import { Global, css } from "@emotion/react";
import { useState } from "react";

const englishToHieroglyphs = new Map([
  ["a", "𓄿"],
  ["b", "𓃀 "],
  ["c", "𓎡"],
  ["d", "𓂧"],
  ["e", "𓂝"],
  ["f", "𓆑"],
  ["g", "𓎼"],
  ["h", "𓉔"],
  ["i", "𓇋"],
  ["j", "𓆓"],
  ["k", "𓎢"],
  ["l", "𓃭"],
  ["m", "𓅓"],
  ["n", "𓈖"],
  ["o", "𓍯"],
  ["p", "𓊪"],
  ["q", "𓈎"],
  ["r", "𓂋"],
  ["s", "𓋴"],
  ["t", "𓏏"],
  ["u", "𓅱"],
  ["v", "𓆒"],
  ["w", "𓅳"],
  ["x", "𓄡"],
  ["y", "𓇌"],
  ["z", "𓊃"],
]);

const hieroglyphsToEnglish = new Map(
  Array.from(englishToHieroglyphs).map((x) => x.reverse() as [string, string])
);

const convert = (map: ReadonlyMap<string, string>) => (text: string) =>
  Array.from(text.toLowerCase())
    .map((char) => map.get(char) ?? char)
    .reduce((acc, curr) => acc + curr, "");

const convertToHieroglyphs = convert(englishToHieroglyphs);

const convertToEnglish = convert(hieroglyphsToEnglish);

const App = () => {
  const [text, setText] = useState("");
  const [hieroglyphs, setHieroglyphs] = useState("");

  return (
    <>
      <Global
        styles={css`
          html,
          body {
            background-color: #ffddbb;
          }
        `}
      />
      <form
        css={{
          position: "fixed",
          inset: 0,
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
        }}
      >
        <div
          css={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            width: "stretch",
            "> input": {
              color: "#311400",
              fontSize: "2rem",
              textAlign: "center",
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderColor: "#311400",
              backgroundColor: "transparent",
              padding: "1rem",
              width: "stretch",
              ":focus": {
                outline: "none",
              },
            },
          }}
        >
          <input
            placeholder="amun ra"
            value={text}
            onChange={(event) => {
              setText(event.target.value);
              setHieroglyphs(convertToHieroglyphs(event.target.value));
            }}
          />
          <input
            placeholder={convertToHieroglyphs("amun ra")}
            value={hieroglyphs}
            onChange={(event) => {
              setHieroglyphs(event.target.value);
              setText(convertToEnglish(event.target.value));
            }}
          />
        </div>
      </form>
    </>
  );
};

export default App;
